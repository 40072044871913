<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_evento">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="id_selected_evento ? schemaEdit : schema"
        @invalid-submit="onInvalidSubmit"
        :v-slot="{ values }"
        :initial-values="id_selected_evento ? selected_evento : formValues"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                id_selected_evento ? "Modifica evento" : "Inserimento evento"
              }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_evento_close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-7">
                      <label class="fw-bold text-gray-600">Nome*</label>
                      <Field name="nome" type="text" class="form-control" />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="nome"
                      />
                    </div>
                    <div class="col-md-5 d-flex flex-column">
                      <label class="fw-bold text-gray-600"
                        >Evento visibile a tutti i circoli
                      </label>
                      <Field
                        class="form-check-input mt-1"
                        type="checkbox"
                        name="visibile_a_tutti_i_circoli"
                        :value="true"
                      />
                      <ErrorMessage
                        name="visibile_a_tutti_i_circoli"
                        style="color: red"
                        class="ms-1"
                      />
                    </div>
                  </div>
                </div>
                <template v-if="!id_selected_evento">
                  <hr class="mb-4 mt-4" />
                  <div class="mb-7 px-5">
                    <FieldArray
                      name="sessioni"
                      v-slot="{ fields, push, remove }"
                    >
                      <Sessione
                        v-for="(field, idx) in fields"
                        :key="field.key"
                        :sessione="field"
                        :idx="idx"
                        :numero_elementi="fields.length"
                        @remove="remove(idx)"
                        @push="push({ nome: '' })"
                        :isLastEl="fields.length == idx + 1"
                      />
                    </FieldArray>
                  </div>
                </template>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_evento_close_button"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              {{ id_selected_evento ? "Modifica" : "Aggiungi" }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref } from "vue";
import { Form, ErrorMessage, Field, FieldArray } from "vee-validate";
import { useStore } from "vuex";
import { format } from "@/composables/formatDate";

import "vue3-date-time-picker/dist/main.css";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Sessione from "./Sessione.vue";
import { schema, schemaEdit } from "@/schemas/schemaEventi";
import { callFunctionAfterAlert } from "@/composables/swAlert";

export default defineComponent({
  name: "addEvento",
  props: {
    selected_evento: {
      type: Object,
    },
    id_selected_evento: {
      type: Number,
    },
  },
  components: {
    Loading,
    Form,
    FieldArray,
    ErrorMessage,
    Field,
    Sessione,
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const snm_corsi_tipo = computed(() =>
    //   store.getters.getStateFromName("snm_corsi_tipo")
    // );
    // const snm_corsi_sottotipo = computed(() =>
    //   store.getters.getStateFromName("snm_corsi_sottotipo")
    // );
    // const istat_regioni_province = computed(() =>
    //   store.getters.getStateFromName("istat_regioni_province")
    // );
    // const statiResidenza = computed(() =>
    //   store.getters.getStateFromName("stati_residenza")
    // );
    // const snm_tipo_organizzazione = computed(() =>
    //   store.getters.getStateFromName("snm_tipo_organizzazione")
    // );
    // const keys = ref("sct|irp|sr|scto|c");
    // if (
    //   !snm_corsi_tipo.value ||
    //   !istat_regioni_province.value ||
    //   !statiResidenza.value ||
    //   !snm_tipo_organizzazione.value
    // ) {
    //   store.dispatch("setStoreData", {
    //     keys: { keys: keys.value },
    //     apiLink: globalApi.COMBOLIST_GET,
    //   });
    // }

    const alertCampiObb = ref(false);

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      // isLoading.value = true;
      alertCampiObb.value = false;
      // const responseAdd = ref({});
      await callFunctionAfterAlert(
        props.id_selected_evento ? "/evento/edit" : "/evento/add",
        values,
        props.id_selected_evento
          ? `Sei sicuro di voler modificare l'evento <br> <b>${values.nome}</b>?`
          : `Sei sicuro di voler creare l'evento <br> <b>${values.nome}</b>?`,
        "Evento creato con successo",
        "Attenzione! Si è verificato un problema nella creazione dell'evento. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          disableButton.value = false;
          resetForm();
          emit("refreshList");
        }
      });
      // await addEvento(values).then(async (value) => {
      //   if (value.status == 200) {
      //     Swal.fire({
      //       html: "Corso creato",
      //       icon: "success",
      //       buttonsStyling: false,
      //       confirmButtonText: "OK",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //     resetForm();
      //     document.getElementById("closeModal").click();
      //     emit("refreshList");
      //   } else {
      //     const strMsg = ref(
      //       value.data.message
      //         ? value.data.message
      //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
      //     );
      //     Swal.fire({
      //       html: strMsg.value,
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "OK",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //   }
      //   disableButton.value = false;
      //   isLoading.value = false;
      // });
    };

    const onInvalidSubmit = (v) => {
      console.log(v);
      alertCampiObb.value = true;
    };

    return {
      schemaEdit,
      schema,
      alertCampiObb,
      onSubmit,
      onInvalidSubmit,
      format,
      stagioni: computed(() => store.getters.stagioni),
      istat_regioni_province: computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      ),
      province: computed(() => store.getters.getStateFromName("province")),
      spliceComuniIstat,
      setComuni,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      societa: computed(() => store.getters.societaNomeCodAff),
      disableButton,
      isLoading,
      formValues: {
        sessioni: [{}],
      },
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
