MODALEDITSESSIONE.VUE
<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_edit_sessione">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        :v-slot="{ values }"
        :initial-values="sessione_selezionata"
      >
        <div class="modal-content" v-if="id_sessione_selezionata">
          <div class="modal-header">
            <h5 class="modal-title">
              Sessione {{ sessione_selezionata.nome }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_edit_sessione_close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="row ps-5 pe-5">
                <div class="col-md-6">
                  <label class="fw-bold text-gray-600">Nome*</label>
                  <Field :name="`nome`" type="text" class="form-control" />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    :name="`nome`"
                  />
                </div>
                <div class="col-md-6">
                  <label class="fw-bold text-gray-600"
                    >Data svolgimento sessione</label
                  >
                  <Datepicker
                    placeholder=""
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    v-model="data"
                    autoApply
                    @update:modelValue="
                      $emit('setData', fromUTCtoISODate($event))
                    "
                  />
                  <Field
                    :name="`data`"
                    v-model="data"
                    type="hidden"
                    class="form-control"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    :name="`data`"
                  />
                </div>
              </div>
              <div class="row ps-5 pe-5 my-3">
                <div class="col-md-4">
                  <label class="fw-bold text-gray-600"
                    >Tipologia sessione*</label
                  >
                  <SelectInput
                    :options="sessioni_tipologia"
                    :name="`id_tipo_sessione`"
                    id="id_Sottotipo_Corso"
                    placeholder="Seleziona"
                  />
                </div>
                <div class="col-md-2">
                  <label class="fw-bold text-gray-600">Prezzo*</label>
                  <Field :name="`prezzo`" type="text" class="form-control" />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    :name="`prezzo`"
                  />
                </div>
                <div class="col-md-6">
                  <label class="fw-bold text-gray-600"
                    >Disponibilità esaurita</label
                  >
                  <br />
                  <Field
                    type="checkbox"
                    :name="`disponibilita_esaurita`"
                    :value="true"
                    style="margin-top: 2%"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    :name="`disponibilita_esaurita`"
                  />
                </div>
              </div>
              <div class="row ps-5 pe-5">
                <div class="col-md-12">
                  <label class="fw-bold text-gray-600">Descrizione*</label>
                  <Field
                    as="textarea"
                    :name="`descrizione`"
                    type="text"
                    class="form-control"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    :name="`descrizione`"
                  />
                </div>
              </div>
            </div>

            <!--end::Card body-->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_edit_sessione_close_button"
            >
              Chiudi
            </button>

            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Modifica
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref, watch } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import { useStore } from "vuex";
import { format, fromUTCtoISODate } from "@/composables/formatDate";

import "vue3-date-time-picker/dist/main.css";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { schema } from "@/schemas/schemaSessione";
import { callFunctionAfterAlert } from "@/composables/swAlert";

import Datepicker from "vue3-date-time-picker";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
export default defineComponent({
  name: "addEvento",
  props: {
    sessione_selezionata: {
      type: Object,
    },
    id_sessione_selezionata: {
      type: Number,
    },
  },
  components: {
    Loading,
    Form,
    Datepicker,
    SelectInput,
    ErrorMessage,
    Field,
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const snm_corsi_tipo = computed(() =>
    //   store.getters.getStateFromName("snm_corsi_tipo")
    // );
    // const snm_corsi_sottotipo = computed(() =>
    //   store.getters.getStateFromName("snm_corsi_sottotipo")
    // );
    // const istat_regioni_province = computed(() =>
    //   store.getters.getStateFromName("istat_regioni_province")
    // );
    // const statiResidenza = computed(() =>
    //   store.getters.getStateFromName("stati_residenza")
    // );
    // const snm_tipo_organizzazione = computed(() =>
    //   store.getters.getStateFromName("snm_tipo_organizzazione")
    // );
    // const keys = ref("sct|irp|sr|scto|c");
    // if (
    //   !snm_corsi_tipo.value ||
    //   !istat_regioni_province.value ||
    //   !statiResidenza.value ||
    //   !snm_tipo_organizzazione.value
    // ) {
    //   store.dispatch("setStoreData", {
    //     keys: { keys: keys.value },
    //     apiLink: globalApi.COMBOLIST_GET,
    //   });
    // }

    const alertCampiObb = ref(false);
    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      // isLoading.value = true;
      alertCampiObb.value = false;
      // const responseAdd = ref({});
      await callFunctionAfterAlert(
        "/sessione/edit",
        values,
        `Sei sicuro di voler modificare la sessione <br> <b> ${values.nome}</b>?`,
        "Sessione Modificata con successo",
        "Attenzione! Si è verificato errore. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          disableButton.value = false;
          emit("refreshList");
        }
      });
    };

    const onInvalidSubmit = (v) => {
      console.log(v);
      alertCampiObb.value = true;
    };

    watch(
      () => props.id_sessione_selezionata,
      () => {
        data.value = props.sessione_selezionata.data;
      }
    );

    const data = ref(
      props.sessione_selezionata ? props.sessione_selezionata.data : null
    );

    return {
      sessioni_tipologia: [
        { id: 1, label: "Diurna" },
        { id: 2, label: "Serale" },
        { id: 3, label: "Giornaliera" },
      ],
      fromUTCtoISODate,
      data,
      schema,
      alertCampiObb,
      onSubmit,
      onInvalidSubmit,
      format,
      stagioni: computed(() => store.getters.stagioni),
      istat_regioni_province: computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      ),
      province: computed(() => store.getters.getStateFromName("province")),
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      societa: computed(() => store.getters.societaNomeCodAff),
      disableButton,
      isLoading,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
